import React from "react";
import Body from "components/Body";
import Paragraphs, {
  ParagraphsPrimary,
  ParagraphsSecondary,
} from "components/Paragraphs";

export default function ParagraphsBlock({ data, ...props }) {
  const components = {
    primary: ParagraphsPrimary,
    secondary: ParagraphsSecondary,
  };

  let ParagraphsComponent = Paragraphs;

  if (Object.prototype.hasOwnProperty.call(components, data.field_theme)) {
    ParagraphsComponent = components[data.field_theme];
  }

  return (
    <div {...props}>
      <div>
        <h2 className="mb-11 text-5xl uppercase font-important after:h-1 after:w-16 after:bg-secondary after:sm:ml-4 ml-0 after:block after:mt-3 after:sm:mt-0 after:sm:inline-block">
          {data.info}
        </h2>
      </div>
      <div className="text-lg lg:w-1/2 w-full">
        <Body value={data.body.value} />
      </div>
      <div className="pt-12">
        <ParagraphsComponent paragraphs={data.field_paragraphs} />
      </div>
    </div>
  );
}
