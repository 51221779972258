import React from "react";
import Link from "next/link";
import { getResource, getResourceCollectionFromContext } from "next-drupal";
import Layout from "components/Layout";
import { DrupalJsonApiParams } from "drupal-jsonapi-params";
import { getGlobals } from "lib/getGlobals";
import Meta from "components/Meta";
import ParagraphsBlock from "components/Block/ParagraphsBlock";
import Image from "next/image";

export default function IndexPage({ nodes, globals, blocks }) {
  return (
    <Layout
      globals={globals}
      newses={nodes.newses}
      partners={nodes.partners}
      home
    >
      <Meta title="Accueil" />

      <section
        id="home-banner"
        className="relative bg-gradient-to-l from-primary-light to-primary lg:py-52 pt-52 pb-40"
      >
        <div className="absolute top-0 right-0 w-full h-full overflow-hidden z-0 opacity-30 bg-ocean-pattern bg-[length:600px] bg-center" />
        <div className="relative container mx-auto px-4 grid lg:grid-cols-12 grid-cols-1 lg:gap-16 xl:gap-24 gap-12 items-stretch lg:py-12 ">
          <div className="relative h-100 flex flex-col lg:col-span-7 order-2 lg:order-1">
            <div className="uppercase text-white mb-8 flex-1 flex flex-col align-center justify-center font-important">
              <div>
                <span className="lg:pl-16 block">Pour des</span>
                <span className="lg:pl-16 block text-3xl sm:text-4xl">
                  territoires
                </span>
                <span className="text-4xl sm:text-5xl lg:mr-2">dynamiques</span>
                <span className="text-base">et</span>
                <span className="lg:pl-40 block text-5xl sm:text-7xl">
                  solidaires
                </span>
              </div>
            </div>
            <p className="text-white text-lg">
              Interco&rsquo; Outre-mer rassemble les intercommunalit&eacute;s ultramarines autour d&rsquo;enjeux communs tout en tenant compte des caract&eacute;ristiques de chaque territoire. Elle garantit un environnement propice aux &eacute;changes et aux r&eacute;flexions. Force de propositions, elle est devenue au fil des ans un acteur incontournable de repr&eacute;sentation des int&eacute;r&ecirc;ts des &eacute;lus d&rsquo;Outre-mer.
            </p>
          </div>
          <div className="lg:col-span-5 lg:order-2">
            <Image
              src="/images/Carte.png"
              height={492}
              width={680}
              layout="responsive"
              alt="Carte interco"
            />
          </div>
          <div className="mt-8 lg:text-xl text-2xl lg:absolute order-3 lg:bottom-0 lg:left-[62%]">
            <div className="relative sm:inline-block block w-fit mb-4 sm:mb-0">
              <Link href="/association" passHref>
                <a className="uppercase text-white after:content-[''] after:block after:absolute after:left-0 after:h-1 after:w-full after:scale-x-50 after:origin-left after:hover:scale-x-100 after:transition-all duration-200 after:bg-secondary">
                  En savoir plus
                </a>
              </Link>
            </div>
            <div className="relative inline-block sm:ml-12">
              <a 
                href="/images/carte-territoires-interco.jpg" 
                className="uppercase text-white after:content-[''] after:block after:absolute after:left-0 after:h-1 after:w-full after:scale-x-50 after:origin-left after:hover:scale-x-100 after:transition-all duration-200  after:bg-secondary"
                target="_blank"
                rel="noreferrer"
              >
                Carte des intercommunalités
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="relative py-14 bg-primary-lighter">
        <div
          className="absolute top-0.5 inset-x-0 -translate-y-full bg-ellipse-header bg-cover bg-top"
          style={{ paddingBottom: "8%" }}
        />
        <div className="overflow-hidden relative">
          <div
            className="absolute -left-80 bottom-0 -translate-y-1/4 opacity-20 hidden lg:block"
            style={{
              width: "30vw",
              height: "30vw",
            }}
          >
            <Image
              src="/images/decorations/martinique.svg"
              height={408}
              width={476}
              layout="responsive"
              alt="Réprésentation de la Martinique"
            />
          </div>
          <div
            className="absolute -right-60 top-40 -translate-y-1/4 opacity-20 hidden lg:block"
            style={{
              width: "30vw",
              height: "30vw",
            }}
          >
            <Image
              src="/images/decorations/mayotte.svg"
              height={644}
              width={475}
              layout="responsive"
              alt="Réprésentation de la Mayotte"
            />
          </div>
          <div className="relative container mx-auto px-4 z-10">
            <ParagraphsBlock data={blocks.missions} />
          </div>
        </div>
      </section>

      <section className="relative pt-14 lg:pb-28 pb-14 bg-white overflow-hidden">
        <div className="absolute hidden lg:block -bottom-14 opacity-30 w-screen">
          <Image
            src="/images/decorations/bottomLine.svg"
            height={203}
            width={1920}
            layout="responsive"
            alt="Ligne du bas"
          />
        </div>
        <div className="absolute hidden lg:block -top-32 right-0 opacity-30 w-1/2">
          <Image
            src="/images/decorations/topLine.svg"
            layout="responsive"
            height={382}
            width={1125}
            alt="Ligne du haut"
          />
        </div>
        <div className="relative container mx-auto px-4">
          <ParagraphsBlock data={blocks.actions} />
        </div>
      </section>
    </Layout>
  );
}

export async function getStaticProps(context) {
  const newsesParams = new DrupalJsonApiParams();
  const partnersParams = new DrupalJsonApiParams();
  const paragraphsBlockParams = new DrupalJsonApiParams();

  newsesParams
    .addInclude(["field_image", "field_type"])
    .addFilter("status", 1)
    .addSort("created", "DESC")
    .addSort("title", "ASC")
    .addPageLimit(3);

  partnersParams
    .addInclude(["field_image"])
    .addFilter("status", 1)
    .addSort("title", "ASC");

  paragraphsBlockParams.addInclude([
    "field_paragraphs",
    "field_paragraphs.field_image",
    "field_paragraphs.field_paragraphs.field_image",
  ]);

  const missions = await getResource(
    "block_content--paragraph",
    "ccf485d4-f88f-4828-a845-616028764494",
    {
      params: paragraphsBlockParams.getQueryObject(),
    }
  );

  const actions = await getResource(
    "block_content--paragraph",
    "154425af-7d43-403d-9686-09a3d55a0025",
    {
      params: paragraphsBlockParams.getQueryObject(),
    }
  );

  const newses = await getResourceCollectionFromContext("node--news", context, {
    params: newsesParams.getQueryObject(),
  });

  const partners = await getResourceCollectionFromContext(
    "node--partners",
    context,
    {
      params: partnersParams.getQueryObject(),
    }
  );

  return {
    props: {
      globals: await getGlobals(context),
      nodes: {
        newses,
        partners,
      },
      blocks: {
        missions,
        actions,
      },
    },
    revalidate: 30,
  };
}
